


































































import { defineComponent, ref } from "@vue/composition-api";
import FiltersNew from "@monorepo/uikit/src/components/common/FiltersNew.vue";
import FiltersTopNew from "@monorepo/uikit/src/components/common/FiltersTopNew.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import { viewTitle } from "@monorepo/utils/src/variables/projectsData/actsView/viewTitle";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/actsView/tableHeaders";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/ActsView/utils/convertApiItemToUi";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TableSearchResults from "@monorepo/uikit/src/components/common/TableSearchResults.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/actsView/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/actsView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/inventory/src/views/ActsView/utils/defaultCurrentFilters";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import InventoryStatus from "@monorepo/uikit/src/components/tableViews/InventoryCountItemsStatus.vue";
import SortPanelContainerNew from "@monorepo/uikit/src/components/common/SortPanelContainerNew.vue";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";
import PreviewTableCard from "@monorepo/inventory/src/components/newView/PreviewTableCard.vue";

const SECTION_NAME = "actsView";

export default defineComponent({
  components: {
    TextFilter,
    SelectFilter,
    InfiniteScroll,
    ScrollPanel,
    TableSearchResults,
    FiltersNew,
    FindButton,
    FiltersTopNew,
    InventoryStatus,
    SortPanelContainerNew,
    PreviewTableCard,
  },
  data() {
    return {
      viewTitle,
      search: "",
      items: [],
      sortFilter: null,
      tableHeaders,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.FILTER_OIK, fields.FILTER_FUND],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user", "userSettings"]),
    ...mapGetters(SECTION_NAME, [
      "cells",
      "data",
      "openedId",
      "isOpenFilters",
      "isSelectAll",
      "infiniteId",
      "totalLength",
      "selectedIds",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
      "kindsLibrary",
      "viewMode",
    ]),
    currentDocumentView(): ViewMode {
      return this.viewMode?.typeDisplay || ViewMode.TABLE;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    headers(): typeof tableHeaders {
      return this.tableHeaders.slice(1);
    },
    resultData(): any {
      return (this.data as any).map(convertApiItemToUi);
    },
    resultFiltersElement(): any {
      return this.filtersElements.slice(1);
    },
    kinds(): { title: string; value: string }[] {
      return convertToSelectItems(this.kindsLibrary);
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        kinds: this.kinds,
      };
    },
    statusColor(): (status: string) => string {
      return (status: string) => {
        switch (status) {
          case "Отправлен в ОИК":
            return "#00A459";
          default:
            return "#2462d1";
        }
      };
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, [
      "getEventList",
      "getExportData",
      "addOpenedId",
      "setIsOpenFilters",
      "getTreeData",
      "clearFilters",
      "setAutorefresh",
    ]),
    ...mapMutations(SECTION_NAME, ["refreshData", "setSelectedIds"]),
    openModal(header: { value: string }, event: Record<string, unknown>) {
      this.$emit("openModal", { header, event });
    },
    togglePdf(element: any) {
      this.$emit("changeIsShowPdf", { value: true, pages: element.pages, uploadFileGuid: element.actFileGuid });
    },
  },
  setup() {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    return {
      sectionName,
    };
  },
});
